<template>
  <theme0 v-if="kindTheme===0" />
  <theme1 v-else-if="kindTheme===1" />
</template>

<script>
export default {
  name: 'TopHeader',
  components: {
    Theme0: () => import('./TopHeaderTheme0'),
    Theme1: () => import('./TopHeaderTheme1'),
  },
  props: {
    kindTheme: {
      type: Number,
      default: 0,
    },
  },
};
</script>
